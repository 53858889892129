import _ from 'lodash'
import dbCodes from '../../../server/dbCodes.js'


const brandColor = '#FF751A'

const white = '#FFFFFF'
const grey10 = '#FAFAFA'
const grey20 = '#F2F2F2'
const grey30 = '#ECECEC'
const grey40 = '#BEBEBE'
const grey50 = '#A8A8A8'
const grey60 = '#646464'
const black = '#000000'

const backgroundWhite = '#FFFFFF'
const backgroundNeutral = '#FAFAFA'
const backgroundNeutral2 = '#F2F2F2'
const backgroundSelected = '#ECECEC'
const backgroundDark = '#242424'

const border = '#BEBEBE'

const textDark = '#646464'
const textMedium = '#A8A8A8'
const textLight = '#FAFAFA'


const buttonSolid = '#646464'
const buttonOnNeutral = '#FFFFFF'
const buttonOnWhite = '#FAFAFA'


const successDark = '#017A5E'
const successBright = '#19C887'
const successLight = '#7DFAC3'
const successPale = '#DFFFEF'
const infoDark = '#3C378C'
const infoBright = '#607FE7'
const infoLight = '#A7D2FF'
const infoPale = '#DFF6FF'
const warningDark = '#BD9F00'
const warningBright = '#FDD608'
const warningLight = '#FFF08C'
const warningPale = '#FFF8C0'
const errorDark = '#A7002C'
const errorBright = '#FF3741'
const errorLight = '#FFBDD9'
const errorPale = '#FFEEF3'

const expected = grey20
const ontheway = infoLight
const completed = successLight
const notCompleted = warningLight
const manco = errorLight
const cancelled = grey40

const pickup = '#607FE7'
const deliveryExpected = '#FFAC62'
const delivery = '#FE7D00'
const pickupDelivery = '#AF7E74'

export default {
    brandColor,

    backgroundWhite,
    backgroundNeutral,
    backgroundNeutral2,
    backgroundSelected,
    backgroundDark,

    border,

    textDark,
    textMedium,
    textLight,

    white,
    grey10,
    grey20,
    grey30,
    grey40,
    grey50,
    grey60,
    black,

    buttonSolid,
    buttonOnNeutral,
    buttonOnWhite,

    successDark,
    successBright,
    successLight,
    successPale,
    infoDark,
    infoBright,
    infoLight,
    infoPale,
    warningDark,
    warningBright,
    warningLight,
    warningPale,
    errorDark,
    errorBright,
    errorLight,
    errorPale,

    expected,
    ontheway,
    completed,
    notCompleted,
    manco,
    cancelled,

    pickup,
    deliveryExpected,
    delivery,
    pickupDelivery,

    isDarkBackground: (bgColor) => {
        if (!bgColor) {
            return false
        }

        const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
        const r = parseInt(color.substring(0, 2), 16) // hexToR
        const g = parseInt(color.substring(2, 4), 16) // hexToG
        const b = parseInt(color.substring(4, 6), 16) // hexToB
        const uicolors = [r / 255, g / 255, b / 255]
        const c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92
            }

            return Math.pow((col + 0.055) / 1.055, 2.4)
        })

        const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2])

        // return (L <= 0.179)
        return (L <= 0.679)
    },

    status: {
        0: expected, // Verwacht
        1: white, // Aangemaakt
        2: ontheway, // Onderweg
        3: notCompleted, // In bewaring
        4: notCompleted, // Niet afgerond
        5: completed, // Afgerond success
        6: cancelled // Geannuleerd
    },

    statusIcon: {
        0: expected, // Verwacht
        1: expected, // Aangemaakt
        2: infoBright, // Onderweg
        3: warningBright, // In bewaring
        4: warningBright, // Niet afgerond
        5: successBright, // Afgerond
        6: cancelled // Geannuleerd
    },

    colloStatusIcon: {
        cancelled,

        expected,
        notsupplied: warningBright,

        pickup: grey40,
        notcollected: warningBright,
        collected: successBright,

        athub: grey40,

        onhold: warningBright,

        outfordelivery: infoBright,

        delivered: successBright,
        notdelivered: warningBright,
        missing: warningBright,
        refused: warningBright,

        manco: errorBright,
        returned: grey40
    },

    orderStatusColor: (order) => {
        let statusColor = white
        let status = ''
        let subStatus = ''

        if (!order) {
            return {statusColor, status, subStatus}
        }

        status = dbCodes.status[order.status]

        if (order.status === dbCodes.status.verwacht()) {
            statusColor = expected
            return {statusColor, status, subStatus}
        }

        const nrOfAddressesCompleted = _.countBy(order.addresses, 'hasArrived').true || 0

        if (order.addresses.length > 1 && nrOfAddressesCompleted > 0 && (order.status === dbCodes.status.aangemaakt() || order.status === dbCodes.status.onderweg())) {
            status = `${dbCodes.status[order.status]} (${nrOfAddressesCompleted}/${order.addresses.length})`
        }

        if (order.status === dbCodes.status.aangemaakt()) {
            statusColor = white
            return {statusColor, status, subStatus}
        }

        if (order.status === dbCodes.status.onderweg()) {
            statusColor = ontheway
            return {statusColor, status, subStatus}
        }


        if (order.status === dbCodes.status.inBewaring()) {
            statusColor = notCompleted
            return {statusColor, status, subStatus}
        }

        if (order.status === dbCodes.status.geannuleerd()) {
            statusColor = cancelled
        }


        if (order.status === dbCodes.status.nietAfgerond()) {
            statusColor = notCompleted
        }

        if (order.status === dbCodes.status.afgerond()) {
            statusColor = completed
        }


        const hasDeliveryAddress = _.some(order.addresses, (address) => address.type === 'delivery')
        const statusses = _.uniq(order.colli.map((collo) => collo.deliveredAtNeighbors ? 'deliveredAtNeighbors' : collo.deliveredInMailbox ? 'deliveredInMailbox' : `${collo.status}_${collo.reason}`))

        if (statusses.length === 1) {
            if (order.colli[0].status === 'manco') {
                statusColor = manco
            }

            if (order.colli[0].deliveredAtNeighbors) {
                subStatus = 'Bezorgd bij buren'
            } else if (order.colli[0].deliveredInMailbox) {
                subStatus = 'Bezorgd in brievenbus'
            } else {
                subStatus = `${dbCodes.colloReasons[order.colli[0].reason] || dbCodes.colloStatus[order.colli[0].status]}${order.colli.length > 1 ? ` (${order.colli.length}/${order.colli.length})` : ''}`
            }
        } else {
            if (hasDeliveryAddress) {
                const deliveryColli = order.colli.filter((collo) => typeof collo?.deliveryAddressIndex === 'number')
                const deliveredColli = order.colli.filter((collo) => collo.status === 'delivered')
                subStatus = `Bezorgd (${deliveredColli.length}/${deliveryColli.length})`
            } else {
                const pickupColli = order.colli.filter((collo) => typeof collo?.pickupAddressIndex === 'number')
                const pickedupColli = order.colli.filter((collo) => collo.status === 'collected')
                subStatus = `Opgehaald (${pickedupColli.length}/${pickupColli.length})`
            }
        }


        return {statusColor, status, subStatus}
    },

    addressStatusColor: (address, colli) => {
        let statusColor = ''
        let status = ''


        if (!colli?.length || address?.status === dbCodes.status.geannuleerd()) {
            return {
                statusColor: cancelled,
                status: dbCodes.colloStatus['cancelled']
            }
        }


        if (address.type === 'pickup') {
            if (!address.hasArrived) {
                return {
                    statusColor: expected,
                    status: dbCodes.colloStatus['pickup']
                }
            }


            const pickedupColli = colli.map((collo) => {
                const {status, reason} = dbCodes.colloPickupStatus(collo)

                return {
                    ...collo,
                    status,
                    reason
                }
            })

            statusColor = notCompleted
            status = pickedupColli[0]?.reason ? dbCodes.colloReasons[pickedupColli[0]?.reason] : dbCodes.colloStatus[pickedupColli[0]?.status]

            const collectedColli = pickedupColli.filter((collo) => collo.status === 'collected')

            if (collectedColli.length === pickedupColli.length) {
                statusColor = completed
            }

            if (pickedupColli.length > 1) {
                status = `Opgehaald (${collectedColli.length}/${pickedupColli.length})`
            }

            return {statusColor, status}
        }

        if (address.type === 'delivery') {
            if (!address.hasArrived) {
                statusColor = '#F2F2F2'

                if (!colli.length) {
                    return {
                        statusColor: '#F2F2F2',
                        status: dbCodes.colloStatus['athub']
                    }
                }

                const expectedColli = colli.filter((collo) => collo.status === 'expected')

                if (expectedColli.length && expectedColli.length === colli.length) {
                    return {
                        statusColor,
                        status: dbCodes.colloStatus['expected']
                    }
                }

                const notsuppliedColli = colli.filter((collo) => collo.status === 'notsupplied')


                if (notsuppliedColli.length && notsuppliedColli.length === colli.length) {
                    status = dbCodes.colloStatus['notsupplied']

                    return {statusColor, status}
                }


                status = dbCodes.colloStatus['athub']

                if (colli.length > 1) {
                    status = `${dbCodes.colloStatus['athub']} (${colli.length - expectedColli.length - notsuppliedColli.length}/${colli.length})`
                }

                return {statusColor, status}
            }

            statusColor = notCompleted
            status = colli[0]?.reason ? dbCodes.colloReasons[colli[0]?.reason] : dbCodes.colloStatus[colli[0]?.status]

            const deliveredColli = colli.filter((collo) => collo.status === 'delivered')

            if (deliveredColli.length === colli.length) {
                statusColor = completed
            }

            if (colli.length > 1) {
                status = `Bezorgd (${deliveredColli.length}/${colli.length})`
            }

            return {statusColor, status}
        }
    },


    addressColliStatus: (order, address, addressColli) => {
        let statusColor = 'transparent'
        let status = ''

        if (!address) {
            return {status, statusColor}
        }

        const colli = []

        const mapColli = addressColli || order.colli

        mapColli.map((collo) => {
            if (address.type === 'pickup' && collo.pickupAddressIndex === address.index && !['cancelled'].includes(collo.status)) {
                collo = structuredClone(collo)

                const {status, reason} = dbCodes.colloPickupStatus(collo)

                collo.status = status
                collo.reason = reason

                colli.push(collo)
            }

            if (address.type === 'delivery' && collo.deliveryAddressIndex === address.index && !['cancelled', 'pickup', 'outforpickup', 'notcollected', 'expected', 'notsupplied'].includes(collo.status)) {
                collo = structuredClone(collo)
                colli.push(collo)
            }
        })


        if (address.type === 'pickup') {
            const pickupColli = colli.filter((collo) => ['cancelled', 'pickup', 'outforpickup'].includes(collo.status))

            if (pickupColli.length === colli.length) {
                return {status, statusColor}
            }

            const statusses = _.uniq(colli.map((collo) => `${collo.status}_${collo.reason}`))

            if (statusses.length === 1) {
                status = `${dbCodes.colloReasons[colli[0].reason] || dbCodes.colloStatus[colli[0].status]}${colli.length > 1 ? ` (${colli.length}/${colli.length})`: ''}`

                if (colli[0].status === 'collected') {
                    statusColor = successBright
                } else {
                    statusColor = warningBright
                }
            } else {
                const pickedupColli = colli.filter((collo) => collo.status === 'collected')
                status = `Opgehaald (${pickedupColli.length}/${colli.length})`

                if (pickedupColli.length === colli.length) {
                    statusColor = successBright
                } else {
                    statusColor = warningBright
                }
            }
        }

        if (address.type === 'delivery') {
            const toDeliver = colli.filter((collo) => ['cancelled', 'collected', 'athub', 'outfordelivery'].includes(collo.status))

            if (toDeliver.length === colli.length) {
                return {status, statusColor}
            }

            const statusses = _.uniq(colli.map((collo) => `${collo.status}_${collo.reason}`))

            if (statusses.length === 1) {
                status = `${dbCodes.colloReasons[colli[0].reason] || dbCodes.colloStatus[colli[0].status]}${colli.length > 1 ? ` (${colli.length}/${colli.length})` : ''}`

                if (colli[0].status === 'delivered') {
                    statusColor = successBright
                } else if (colli[0].status === 'manco') {
                    statusColor = errorBright
                } else {
                    statusColor = warningBright
                }
            } else {
                const deliveredColli = colli.filter((collo) => collo.status === 'delivered')
                status = `Bezorgd (${deliveredColli.length}/${colli.length})`

                if (deliveredColli.length === colli.length) {
                    statusColor = successBright
                } else {
                    statusColor = warningBright
                }
            }
        }

        return {statusColor, status}
    },

    colloStatusColor: (type, collo) => {
        let status = ''
        let reason = ''
        let statusColor = successBright

        if (type === 'pickup') {
            status = ['cancelled', 'pickup', 'outforpickup', 'notcollected'].includes(collo.status) ? dbCodes.colloStatus[collo.status] : dbCodes.colloStatus['collected']
            reason = collo.status === 'notcollected' ? dbCodes.colloReasons[collo.reason] : ''

            if (['cancelled', 'pickup'].includes(collo.status)) {
                statusColor = grey40
            }

            if (collo.status === 'outforpickup') {
                statusColor = ontheway
            }

            if (collo.status === 'notcollected') {
                statusColor = notCompleted
            }
        } else if (type === 'delivery') {
            status = dbCodes.colloStatus[collo.status]
            reason = collo.status !== 'notcollected' ? dbCodes.colloReasons[collo.reason] : ''

            if (['cancelled', 'expected', 'pickup', 'outforpickup', 'collected', 'notcollected', 'athub', 'returned'].includes(collo.status)) {
                statusColor = grey40
            }

            if (collo.status === 'outfordelivery') {
                statusColor = ontheway
            }

            if (['notsupplied', 'onhold', 'notdelivered', 'missing', 'refused'].includes(collo.status)) {
                statusColor = notCompleted
            }

            if (['manco'].includes(collo.status)) {
                statusColor = manco
            }

            if (collo.status === 'delivered' && collo.deliveredAtNeighbors && collo.nrNeighbor) {
                reason = `Bij buren op nummer ${collo.nrNeighbor}`
            }

            if (collo.status === 'delivered' && collo.deliveredInMailbox) {
                reason = 'In brievenbus'
            }
        } else {
            status = dbCodes.colloStatus[collo.status]
            reason = dbCodes.colloReasons[collo.reason]


            if (['cancelled', 'expected', 'pickup', 'athub', 'returned'].includes(collo.status)) {
                statusColor = grey40
            }

            if (collo.status === 'outforpickup' || collo.status === 'outfordelivery') {
                statusColor = ontheway
            }

            if (['notsupplied', 'onhold', 'notcollected', 'notdelivered', 'missing', 'refused'].includes(collo.status)) {
                statusColor = notCompleted
            }

            if (['manco'].includes(collo.status)) {
                statusColor = manco
            }

            if (collo.status === 'delivered' && collo.deliveredAtNeighbors && collo.nrNeighbor) {
                reason = `Bij buren op nummer ${collo.nrNeighbor}`
            }

            if (collo.status === 'delivered' && collo.deliveredInMailbox) {
                reason = 'In brievenbus'
            }
        }


        return {status, reason, statusColor}
    },

    parcelStatus: {
        1: grey40, // Aangemaakt
        2: grey40, // Bevestigd
        3: infoLight, // Opgehaald
        4: infoLight, // Onderweg
        5: errorBright, // Manco
        6: successBright, // Op afhaalpunt
        7: successBright, // Bezorgd
        8: warningBright, // Retour
        9: grey50, // Geannuleerd
        10: successBright // Zie T&T link
    },

    routeColors: [
        '#D50000',
        '#C51162',
        '#AA00FF',
        '#6200EA',
        '#304FFE',
        '#2962FF',
        '#0091EA',
        '#00B8D4',
        '#00BFA5',
        '#00C853',
        '#64DD17',
        '#AEEA00',
        '#FFD600',
        '#FFAB00',
        '#FF6D00',
        '#DD2C00',

        '#1CE6FF', '#FF34FF', '#FF4A46', '#008941', '#006FA6', '#A30059',
        '#0000A6', '#63FFAC', '#B79762', '#004D43', '#8FB0FF', '#997D87',
        '#5A0007', '#809693', '#1B4400', '#4FC601', '#3B5DFF', '#4A3B53', '#FF2F80',
        '#61615A', '#BA0900', '#6B7900', '#00C2A0', '#FFAA92', '#FF90C9', '#B903AA', '#D16100',
        '#7B4F4B', '#A1C299', '#300018', '#0AA6D8', '#013349', '#00846F',
        '#FFB500', '#A079BF', '#CC0744',
        '#00489C', '#6F0062', '#0CBD66', '#EEC3FF', '#456D75', '#B77B68', '#7A87A1', '#788D66',
        '#885578', '#FAD09F', '#FF8A9A', '#D157A0', '#BEC459', '#456648', '#0086ED', '#886F4C',
        '#34362D', '#B4A8BD', '#00A6AA', '#452C2C', '#636375', '#A3C8C9', '#FF913F', '#938A81',
        '#575329', '#00FECF', '#B05B6F', '#8CD0FF', '#3B9700', '#04F757', '#C8A1A1', '#1E6E00',
        '#7900D7', '#A77500', '#6367A9', '#A05837', '#6B002C', '#D790FF', '#9B9700',
        '#549E79', '#72418F', '#BC23FF', '#99ADC0', '#3A2465', '#922329',
        '#404E55', '#0089A3', '#CB7E98', '#A4E804', '#324E72'
    ]
}
